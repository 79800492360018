.myUserPage {
  display: 'flex';
  flex-direction: 'column';
  background-color: '#f5f5f5';
  padding: 0;
  margin: 0;
  width: '-webkit-fill-available';
  position: 'fixed';
  top: 56;
  bottom: 0;
}

.myUserPage .contacts_area {
  display: flex;
  flex: 1;
  height: 100%;
  padding-bottom: 50px;
}

.myUserPage .contacts_area .contacts {
  display: flex;
  flex: 1;
  margin: 1;
  padding: 1;
}
.myUserList_area {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.myUserList_area .myUserList {
  flex: 1;
  overflow-y: auto;
  margin-top: 10px;
}

.myUserList_area .myUserList .myUser {
  display: flex;
  flex-direction: row;
  height: 60px;
}

.addUserPage_area {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.addUserPage_area .myUserList {
  flex: 1;
  overflow-y: auto;
  margin-top: 10px;
}

.addUserPage_area .myUserList .myUser {
  display: flex;
  flex-direction: row;
  height: 60px;
}

.myUserPage .contacts_area .loading_area {
  width: 100%;
  text-align: center;
  align-content: center;
}