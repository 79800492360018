.message-area {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  margin-top: 15px;
}

.message-area .message-user {
  display: flex;
}

.message-area .message-user .message-user-name {
  height: 25px;
  padding: 5px;
  align-content: center;
}

.message-area .message-user .message-user-name-right {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.message-area .message-user .message-user-name-left {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.message-area .message-head-left {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
}

.message-area .message-head-right {
  display: flex;
  flex-direction: row-reverse;
  padding-left: 10px;
  padding-right: 10px;
}

.message-area .message-date-left {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  margin-left: 10px;
}

.message-area .message-date-right {
  display: flex;
  flex-direction: row-reverse;
  font-size: 10px;
  margin-right: 10px;
}