.navbar {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #676767;
  color: white;
  /* border-bottom: 2px solid #3d3d3d; */
}
 
.navbar-content {
  /* max-width: 1200px; */
  /* min-width: 400px; */
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}
 
.navbar-toggle {
  cursor: pointer;
  user-select: none;
}
 
.navbar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 0;
  /* display: none; */
}
 
.navbar-menu.is-expanded {
  max-height: 50px; /* Adjust max-height as needed */
  transition-duration: 0.5s;
  display: flex;
}
 
.navbar-menu li {
  padding: 10px 15px;
}
 
.navbar-menu a {
  text-decoration: none;
  color: white;
}
 
.navbar-menu a:hover {
  background-color: #555;
}

.navbar-content .tag {
  color: aliceblue;
  border-left: 1px solid #3d3d3d;
}

.addChatUser {
  height: 60vh;
  display: flex;
  flex-direction: column;
}

.addChatUser .userList {
 flex: 1;
}

.addChatUser .addChatButton {
  width: 100px;
  align-self: center;
  justify-content: center;
}