.systemMessagePage {
  display: 'flex';
  flex-direction: 'column';
  background-color: '#f5f5f5';
  padding: 0;
  margin: 0;
  width: '-webkit-fill-available';
  position: 'fixed';
  top: 56;
  bottom: 0;
}

.systemMessagePage .headText {
  text-align: center;
  margin: 10px;
  padding: 0px;
}

.systemMessagePage .contacts_area {
  display: flex;
  flex: 1;
  height: 100%;
  padding-bottom: 50px;
}

.systemMessagePage .contacts_area .contacts {
  display: flex;
  flex: 1;
  margin: 1;
  padding: 1;
}

.systemMessagePage .contacts_area .messageList {
  flex: 1;
  overflow-y: auto;
  margin-top: 10px;
}

.systemMessagePage .contacts_area .messageList .messageItem {
  display: flex;
  flex-direction: row;
  height: 60px;
}

.systemMessagePage .contacts_area .loading_area {
  width: 100%;
  text-align: center;
  align-content: center;
}