
.message-bubble-area .message-bubble-left {
    position: relative;
    background-color: #c4c4c4;
    border-radius: 10px;
    padding: 10px;
    /* text-align: center; */
    text-align: left;
    margin: 10px;
    min-width: 200px;
    max-width: 30%;
    word-wrap: break-word; /* 旧版浏览器支持 */
    overflow-wrap: break-word; /* 标准属性 */
    white-space: pre-wrap;
}
 
.message-bubble-area .message-bubble-left-m {
  position: relative;
  background-color: #c4c4c4;
  border-radius: 10px;
  padding: 10px;
  /* text-align: center; */
  text-align: left;
  margin: 10px;
  min-width: 200px;
  max-width: 80%;
  word-wrap: break-word; /* 旧版浏览器支持 */
  overflow-wrap: break-word; /* 标准属性 */
  white-space: pre-wrap;
}

.message-bubble-area .message-bubble-left:after {
    content: '';
    position: absolute;
    top: 10px; /* 三角形的位置 */
    /* bottom: 90%; 三角形的位置 */
    left: 0; /* 居中三角形 */
    margin-left: -10px; /* 调整三角形宽度的一半 */
    border-width: 10px 10px 0; /* 上三角形的大小 */
    border-style: solid;
    border-color: #c4c4c4 transparent transparent; /* 三角形颜色与背景色一致 */
}

.message-bubble-area .message-bubble-right {
    position: relative;
    background-color: #49a7ff;
    border-radius: 10px;
    padding: 10px;
    min-width: 200px;
    /* text-align: center; */
    text-align: left;
    margin: 10px;
    float: right;
    max-width: 80%;
    word-wrap: break-word; /* 旧版浏览器支持 */
    overflow-wrap: break-word; /* 标准属性 */
    white-space: pre-wrap;
}

.message-bubble-area .message-bubble-right-m {
  position: relative;
  background-color: #49a7ff;
  border-radius: 10px;
  padding: 10px;
  min-width: 200px;
  /* text-align: center; */
  text-align: left;
  margin: 10px;
  float: right;
  max-width: 30%;
  word-wrap: break-word; /* 旧版浏览器支持 */
  overflow-wrap: break-word; /* 标准属性 */
  white-space: pre-wrap;
}

.message-bubble-area .message-bubble-right:after {
    content: '';
    position: absolute;
    top: 10px; /* 三角形的位置 */
    /* bottom: 90%; 三角形的位置 */
    right: -10px; /* 居中三角形 */
    margin-left: -10px; /* 调整三角形宽度的一半 */
    border-width: 10px 10px 0; /* 上三角形的大小 */
    border-style: solid;
    border-color: #49a7ff transparent transparent; /* 三角形颜色与背景色一致 */
}
