.chatPage {
  display: 'flex';
  flex-direction: 'column';
  background-color: '#f5f5f5';
  padding: 0;
  margin: 0;
  width: '-webkit-fill-available';
  position: 'fixed';
  top: 56;
  bottom: 0;
}

.chatPage .getMessageloading {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.chatPage .chatHeard {
  display: flex;
}

.chatPage .chatHeard .chatName {
  margin: 0px;
  padding: 5px;
  flex: 1;
}

.chatPage .chatHeard .navbar-toggle {
  display: flex;
  width: 50px;
  padding: 5px;
  align-items: center;
  justify-content: center;
}