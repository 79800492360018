.addUsersChatPage {
  display: 'flex';
  flex-direction: 'column';
  background-color: '#f5f5f5';
  padding: 0;
  margin: 0;
  width: '-webkit-fill-available';
  position: 'fixed';
  top: 56;
  bottom: 0;
}

.addUsersChatPage .headText {
  text-align: center;
  margin: 10px;
  padding: 0px;
}

.addUsersChatPage .contacts_area {
  display: flex;
  flex: 1;
  height: 100%;
  padding-bottom: 50px;
}

.addUsersChatPage .contacts_area .addChatButton {
  padding: 8px;
  margin-top: 0px;
  margin-bottom: -8px;
  margin-left: -8px;
  margin-right: -8px;
  border-radius: 0px 0px 4px 4px; /* 顺序为左上角、右上角、右下角、左下角 */
  border-top: 1px solid rgb(204, 204, 204);
}

.addUsersChatPage .contacts_area .contacts {
  display: flex;
  flex: 1;
  margin: 1;
  padding: 1;
}

.addUsersChatPage .contacts_area .userList {
  flex: 1;
  overflow-y: auto;
  margin-top: 10px;
}

.addUsersChatPage .contacts_area .userList .userItem {
  display: flex;
  flex-direction: row;
  height: 60px;
}

.addUsersChatPage .contacts_area .loading_area {
  width: 100%;
  text-align: center;
  align-content: center;
}