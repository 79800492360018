.User_Icon {
    /* right: 50px; */
    width: 100%;
    justify-content: end;
}

.main_button {
    background-color: rgb(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    border: 0ch;
    position: fixed;
    top: 0px;
    /* flex-direction: column; */
    /* padding: 10px; */
}

.navigotor_area {
    height: 100%;
    flex-direction: column;
    overflow: auto;
    display: flex;
    min-height: 500px;
}

.navigotor_area .chatlist_area {
    flex: 1;
    height: 100%;
    overflow-y: auto;
}

.main {
    display: flex;
    flex-direction: column;
    position: relative;
}

.main_view {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* position: absolute;
    width: 100%; */
}

.notification-dot {
    width: 10px; /* 设置宽度 */
    height: 10px; /* 设置高度 */
    background-color: #ff0000; /* 设置背景颜色为红色 */
    border-radius: 50%; /* 设置圆形 */
    position: absolute; /* 绝对定位 */
    right: 5px; /* 距离右侧的位置 */
  }